/***** Fonts ****/
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@400;500;600;700&display=swap");
@font-face {
  font-family: "Uber Move Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Uber Move Medium"),
    url("../fonts/UberMoveMedium.woff") format("woff");
}
@font-face {
  font-family: "Uber Move Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Uber Move Bold"), url("../fonts/UberMoveBold.woff") format("woff");
}

/***** common formating ****/
body {
  font-family: "Mulish", sans-serif;
  font-size: 18px;
  line-height: 26px;
  font-weight: 400;
}
html,
body {
  background-color: #000;
  color: #fff;
}
a {
  color: #fff;
  text-decoration: none;
}
h1,
h2,
h3,
h4 {
  font-family: "Uber Move Medium";
  font-weight: normal;
}
.steps {
  display: flex;
  justify-content: space-evenly;
  letter-spacing: 1px;
  margin-top: 2rem;
}
.step.active{
  color: #e50914;

}
.stream{
  display: flex;
  width: 75%;
  justify-content: space-between;
  margin-left: 150px;
}
.streamh1{
  /* font-family: '"Uber Move Medium"'; */
font-style: normal;
font-weight: 500;
font-size: 25px;
margin-top: 43px;
/* line-height: 29px; */
}

.streamboxh5{
  /* font-family: 'Uber Move'; */
font-style: normal;
font-weight: 300;
opacity: 0.8;

font-size: 16px;
line-height: 140%;
}
.streamboxContent{
  margin-left: 30px;
  margin-top: 30px;
  

}
.formmain{
  margin-top: 60px;
}
.streamboxContenth4{
  margin-top: 20px;
/* font-family: 'Uber Move'; */
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 21px
}
.streamboxContentli{
  margin-top: 10px;
  
font-family: 'Mulish';
font-style: normal;
font-weight: 300;
font-size: 14px;
line-height: 18px;
opacity: 0.8;
}
.streammain{
  margin-left: 300px;
}
.streamboxActive{
  border:2px solid red !important;

}
.streambox{
  border:2px solid #252525;
margin-top: 30px;
height: 341px;
width: 410px;
left: 0px;
top: 0px;
border-radius: 5px;
background: #252525;


}

.card{
  width: 1090px;
height: 758px;
left: 245px;
top: 284px;

background: rgba(134, 134, 134, 0.1);
box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.2);
border-radius: 5px;
}
/* .step {
  margin: 0.5rem 3rem;
  display: flex;
  color: #ccc;
} */

/* .steps {
  display: flex;
  justify-content: center;
  letter-spacing: 1px;
  margin-top: 2rem;
}

    div:first-of-type {
      border: 1px solid #ccc;
      padding: 5px;
      border-radius: 50%;
      margin-right: 0.5rem;
      width: 2rem;
      height: 2rem;
      text-align: center;
      line-height: 100%;
      padding-top: 7%;
    } */

/***** Grids ****/
.flex-container {
  max-width: 1300px;
  margin: auto;
  padding-left: 30px;
  padding-right: 30px;
}
.flex-row {
  display: flex;
  flex-wrap: wrap;
}
.flex-col {
  flex: 1 0 0%;
  max-width: 100%;
  position: relative;
}
.flex-col-1 {
  flex: 0 0 auto;
  max-width: 100%;
  width: 100%;
  min-height: 1px;
  position: relative;
}
.flex-col-2 {
  flex: 0 0 50%;
  max-width: 50%;
  width: 100%;
  min-height: 1px;
  position: relative;
}
.flex-col-3 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
  width: 100%;
  min-height: 1px;
  position: relative;
}
.flex-col-4 {
  flex: 0 0 25%;
  max-width: 25%;
  width: 100%;
  min-height: 1px;
  position: relative;
}
.flex-col-30 {
  flex: 0 0 30%;
  max-width: 30%;
  width: 100%;
  min-height: 1px;
  position: relative;
}
.flex-col-35 {
  flex: 0 0 35%;
  max-width: 35%;
  width: 100%;
  min-height: 1px;
  position: relative;
}
.flex-col-65 {
  flex: 0 0 65%;
  max-width: 65%;
  width: 100%;
  min-height: 1px;
  position: relative;
}
.flex-col-70 {
  flex: 0 0 70%;
  max-width: 70%;
  width: 100%;
  min-height: 1px;
  position: relative;
}
.gutter-space {
  margin: 0 -15px;
}
.gutter-space > * {
  padding: 0 15px;
}

/***** Buttons ****/
.primary-button {
  background-color: #e50914;
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  outline: 0 !important;
  border: 1px solid #e50914;
  box-shadow: 0px 15px 33px rgba(229, 9, 20, 0.15);
  border-radius: 100px;
  padding: 15px 78px 15px 78px;
  margin-top: 5px;
  font-family: "Mulish", sans-serif;
}

.primary-buttonn {
  background-color: #e50914;
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  outline: 0 !important;
  border: 1px solid #e50914;
  box-shadow: 0px 15px 33px rgba(229, 9, 20, 0.15);
  border-radius: 100px;
  padding: 15px 78px 15px 78px;
  margin-top: 75px;
  font-family: "Mulish", sans-serif;
  align-items: center !important;
  justify-content: center !important;
  
}
.primary-button:hover {
  box-shadow: none;
}
.secondary-button {
  background-color: transparent;
  cursor: pointer;
  color: #fff;
  text-align: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 23px;
  outline: 0 !important;
  border: 1px solid #fff;
  border-radius: 100px;
  padding: 15px 78px 15px 78px;
  margin-top: 5px;
  font-family: "Mulish", sans-serif;
}
.secondary-button:hover {
  box-shadow: none;
}
.grey-wrap .primary-button {
  padding-top: 13px;
  padding-bottom: 14px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  filter: drop-shadow(0px 4px 20px rgba(229, 9, 20, 0.3));
}
.popup-model-alert .primary-button,
.popup-model-alert .secondary-button {
  padding: 11px 30px;
  margin-left: 15px;
  margin-right: 15px;
}

/***** Header ****/

.flex-header {
  display: flex;
  align-items: center;
  padding: 30px 0px 27px 0px;
}
.logo {
  margin-right: 70px;
}
.btn-canvas-menu {
  display: none;
  background-color: transparent;
  border: 0;
  outline: 0 !important;
  cursor: pointer;
  margin: -5px 30px 0 0px;
  padding: 0;
}
.account-links {
  display: flex;
  margin-left: auto;
  align-items: center;
}
.account-links .icon {
  display: block;
  font-size: 0;
  text-indent: 9999px;
  width: 18px;
  height: 18px;
}
.account-links .signin {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  padding: 11px 40px 12px 40px;
}
.account-links .search {
  background: url("../img/icon-search.svg") 0 center no-repeat;
  margin-left: 30px;
}
.account-links .notification-bell {
  background: url("../img/icon-bell.svg") 0 center no-repeat;
  margin-left: 30px;
  position: relative;
}
.notification-bell .count {
  position: absolute;
  z-index: 1;
  top: 2px;
  right: 1px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #e50914;
}
.account-links .account-dropdown {
  position: relative;
  background: url("../img/icon-menu.svg") right center no-repeat;
  margin-left: 30px;
}
.account-links .account-dropdown .icon {
  text-indent: 0;
  width: 42px;
}
.account-dropdown .icon img {
  height: 18px;
  width: 18px;
  border-radius: 2px;
  vertical-align: top;
}
.account-dropdown ul {
  display: none;
  padding-top: 10px;
  overflow: hidden;
  list-style: none;
  min-width: 176px;
  position: absolute;
  z-index: 9;
  right: 0;
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.account-dropdown:hover ul {
  display: block;
}
.account-dropdown li a {
  white-space: nowrap;
  background: #211e1e;
  font-size: 16px;
  line-height: 20px;
  padding: 15px 20px;
  display: block;
  border-top: 1px solid #292626;
}
.account-dropdown li:first-child a {
  border: 0;
}
.app-menu ul {
  display: flex;
  align-items: center;
}
.app-menu li {
  margin-right: 50px;
  border-bottom: 2px solid transparent;
  position: relative;
}
.app-menu li img {
  margin-right: 5px;
  margin-bottom: 5px;
  vertical-align: middle;
}
.app-menu li a {
  display: block;
}
.app-menu li:after {
  content: "";
  position: absolute;
  left: 0.5px;
  bottom: -3px;
  height: 2px;
  width: 0px;
  background: #e50914;
  transition: all 0.5s ease;
}
.app-menu li.active:after,
.app-menu li:hover:after {
  width: 100%;
}

/***** Footer ****/
.app-footer {
  padding: 100px 0 90px 0px;
  background-color: #171414;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.2);
}
.app-footer .flex-container {
  max-width: 1216px;
}
.footer-content {
  padding-left: 130px;
}
.app-footer .question {
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 35px;
}
.footer-menu {
  font-size: 18px;
  line-height: 120%;
  margin-bottom: 100px;
}
.footer-menu ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.footer-menu li {
  max-width: 20%;
  margin-bottom: 20px;
}
.copyright {
  border-top: 1px solid #2e2b2b;
  padding-top: 30px;
  text-align: center;
}

/***** Mobile canvas menu ****/
.canvas-menu {
  visibility: hidden;
  position: fixed;
  left: -250px;
  top: 0;
  z-index: 999999999;
  width: 250px;
  height: 100%;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  background-color: #171414;
  transition: all 0.5s ease-in-out;
}
.canvas-menu.show {
  -webkit-box-shadow: 200px 20px 48px 30px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 200px 20px 48px 30px rgba(0, 0, 0, 0.6);
  box-shadow: 200px 20px 48px 30px rgba(0, 0, 0, 0.6);
}
.canvas-menu-items {
  position: relative;
  padding-top: 50px;
  height: 95vh;
  overflow: auto;
}
.canvas-menu-items .user {
  text-align: center;
  margin: 0px 0 50px 0px;
}
.canvas-menu-items .user h3 {
  font-weight: 500;
  font-size: 26px;
  line-height: 30px;
  color: #e50914;
  margin: 10px 0 7px 0px;
  font-family: "Mulish", sans-serif;
}
.canvas-menu-items .user h4 {
  font-size: 20px;
  line-height: 23px;
  font-weight: 500;
  margin: 0;
  font-family: "Mulish", sans-serif;
}
.canvas-menu-items ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.canvas-menu-items li {
  margin-bottom: 10px;
}
.canvas-menu-items li a {
  display: block;
  padding: 10px 20px 10px 20px;
  font-weight: bold;
  font-size: 18px;
  line-height: 12px;
}
.canvas-menu-items li img {
  vertical-align: middle;
  margin-right: 10px;
}
.btn-menu-hide {
  position: absolute;
  top: 55px;
  right: -25px;
  background: url("../img/icon-close-menu.svg") center center no-repeat;
  width: 16px;
  height: 16px;
  font-size: 0;
  text-indent: 99999px;
  border: 0;
  outline: 0;
}

/* sidebar model */
.sidebar-model {
  overflow-y: auto;
  visibility: hidden;
  position: fixed;
  right: -498px;
  top: 0;
  z-index: 99;
  width: 498px;
  height: 100%;
  background: #211e1e;
  box-shadow: -4px 3px 35px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 5px 5px;
  transition: all 0.5s ease-in-out;
}
.sidebar-model.show {
  visibility: visible;
  right: 0;
}
.sidebar-model .model-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(134, 134, 134, 0.1);
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 25px 30px;
}
.sidebar-model .model-head h2 {
  margin: 0;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;
  font-family: "Mulish", sans-serif;
  color: rgba(255, 255, 255, 0.8);
}
.sidebar-model .model-body {
  min-height: 100px;
  background: #0e0e0e;
}
.sidebar-model .model-close {
  font-size: 20px;
  margin-left: auto;
}
.sidebar-model .model-body {
  padding: 16px;
}
.overlay {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #171414;
  opacity: 0.9;
}
.overlay.show {
  display: block;
}
.scroll-none {
  overflow: hidden;
}
.rating-sidebar-model .model-head {
  background: transparent !important;
  box-shadow: none !important;
}

/* popup model */
.popup-model {
  display: none;
  position: absolute;
  z-index: 999999999;
  left: 50%;
  top: 50%;
  border-radius: 6px;
  background-color: #211e1e;
  transform: translate(-50%, -50%);
  width: 572px;
  min-height: 300px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.popup-model.show {
  display: block;
}
.popup-model .model-head {
  position: relative;
  border-bottom: 1px solid #4d4b4b;
  padding: 25px 30px;
}
.popup-model .model-head h2 {
  margin: 0;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
  font-family: "Mulish", sans-serif;
  color: rgba(255, 255, 255, 0.8);
}
.popup-model .model-body {
  min-height: 100px;
}
.popup-model .model-close {
  font-size: 20px;
  font-weight: 400;
  position: absolute;
  right: 25px;
  top: 22px;
  border: 0;
  outline: 0;
  background: transparent;
}
.popup-model .model-content {
  padding: 30px 30px 40px;
}
.popup-model-alert {
  display: none;
  position: absolute;
  z-index: 999999999;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 568px;
  min-height: 300px;
  background: rgba(23, 20, 20, 1);
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.popup-model-alert.show {
  display: block;
}
.popup-model-alert .model-close {
  font-size: 20px;
  margin-left: auto;
}
.popup-model-alert .model-head {
  padding: 20px 30px 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.popup-model-alert .model-body {
  min-height: 100px;
  padding: 20px 30px 30px 30px;
  text-align: center;
}
.popup-model-alert .model-body h2 {
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  font-family: "Mulish", sans-serif;
  color: rgba(255, 255, 255, 1);
}
.bookEvent-pop {
  width: 700px;
}
.bookEvent-scroll {
  overflow-y: scroll;
  max-height: 400px;
}
.bookEvent-scroll::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
.bookEvent-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}
.bookEvent-scroll::-webkit-scrollbar-thumb {
  background-color: rgb(65, 65, 65);
  outline: 1px solid rgb(65, 65, 65);
}

/***** pages ****/
.img img {
  width: 100%;
  max-width: auto;
}
.text-center {
  text-align: center;
}
.relative {
  position: relative;
}
.hero-section .flex-row {
  align-items: center;
}
.benefits-section {
  padding: 100px 0 140px 0px;
  text-align: center;
}
.hero-section {
  background: rgb(23, 20, 20);
  background: linear-gradient(
    0deg,
    rgba(23, 20, 20, 1) 0%,
    rgba(0, 0, 0, 1) 100%
  );
}
.hero-section .content {
  padding-left: 70px;
}
.hero-section h1 {
  font-family: "Uber Move Bold";
  font-size: 48px;
  line-height: 55px;
  letter-spacing: 0.015em;
  margin-bottom: 10px;
}
.hero-section h1 span {
  color: #e50914;
}
.hero-section h2 {
  font-family: "Uber Move Bold";
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.015em;
  margin-bottom: 25px;
}
.hero-section p {
  max-width: 452px;
  font-weight: normal;
  font-size: 20px;
  line-height: 140%;
}
.hero-section .primary-button {
  margin-top: 20px;
}
.hero-section .img {
  margin-top: -50px;
}
.seo-section {
  background: #000;
  padding-top: 30px;
  padding-bottom: 10px;
}
.seo-section .flex-row {
  align-items: center;
}
.seo-section .content {
  padding-left: 70px;
}
.seo-section h1 {
  font-size: 25px;
  line-height: 31px;
  letter-spacing: 0.015em;
  margin-bottom: 10px;
}
.seo-section h1 span {
  color: #e50914;
}
.socialmedia {
  width: 30px;
  position: absolute;
  left: 0;
  top: 20px;
  display: flex;
  flex-direction: column;
}
.socialmedia a {
  display: block;
  margin-bottom: 20px;
}
.socialmedia .title {
  padding-right: 100px;
  font-size: 18px;
  line-height: 23px;
  transform-origin: 0 0;
  transform: rotate(-90deg);
  position: relative;
  bottom: -95px;
}
.socialmedia .title::after {
  content: "";
  height: 1px;
  width: 39px;
  background-color: red;
  float: left;
  margin-top: -11px;
  margin-left: 67px;
}
.benefits-section {
  text-align: center;
  background-color: transparent !important;
}
.benefits-section .flex-container {
  max-width: 1075px;
}
.home-item {
  letter-spacing: 0.015em;
  padding: 130px 0px;
}
.home-item:nth-child(even) {
  background-color: #171414;
}
.home-item:nth-child(odd) {
  background-color: #151212;
}

.home-item .flex-row {
  align-items: center;
}
.home-item h3 {
  font-size: 50px;
  line-height: 60px;
  margin-bottom: 20px;
}
.home-item p {
  font-weight: normal;
  font-size: 20px;
  line-height: 140%;
  max-width: 100%;
}
.home-item .primary-button {
  margin-top: 30px;
}
.newsletter {
  background-color: #151212;
  text-align: center;
  padding-top: 130px;
}
.newsletter .flex-container {
  max-width: 737px;
}
.newsletter h4 {
  font-size: 35px;
  line-height: 42px;
  letter-spacing: 0.015em;
  margin-bottom: 17px;
}
.newsletter p {
  font-size: 18px;
  line-height: 23px;
}
.newsletter .img {
  margin-top: 38px;
}
.form-newsletter {
  margin-top: 48px;
}
.form-newsletter .form {
  max-width: 627px;
  height: 62px;
  background: #ffffff;
  box-shadow: 0px 15px 33px rgba(0, 0, 0, 0.29);
  border-radius: 38px;
  margin: auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.form-newsletter input {
  border: 0;
  padding-left: 25px;
  width: 50%;
  font-size: 16px;
  line-height: 20px;
  color: #171414;
}
.form-newsletter .subscribe {
  height: 62px;
  margin-right: -2px;
  margin-top: 0;
  font-size: 18px;
  line-height: 23px;
  font-weight: normal;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  -moz-border-radius-topleft: 0px;
  -moz-border-radius-bottomleft: 0px;
  -webkit-border-top-left-radius: 0px;
  -webkit-border-bottom-left-radius: 0px;
}

.event-graph {
  padding: 30px;
}
.event-graph img {
  width: 100%;
}
.monthwise .custom-select {
  border: 1px solid #ffffff;
  border-radius: 100px;
  font-size: 16px;
  line-height: 20px;
  width: 147px;
  height: 40px;
  padding: 0 10px 0 15px;
}
.filter-bar {
  display: flex;
  margin-bottom: 24px;
}
.filter-bar .monthwise {
  margin-left: auto;
  display: flex;
}
.custom-select select {
  width: 100%;
  border: 0;
  background: transparent;
  height: 40px;
  color: #fff;
}
.monthwise select {
  height: 38px;
  padding-left: 20px;
  background: url("../img/icon-date.svg") 0 center no-repeat,
    url("../img/icon-menu.svg") right center no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.events-section {
  margin-bottom: 100px;
}
.events-listing {
  margin-top: 40px;
}
.events-listing .title {
  display: flex;
  align-items: center;
}
.events-listing h2 {
  font-family: "Uber Move Bold";
  font-size: 30px;
  line-height: 36px;
  margin: 0;
}
.events-listing h3 {
  font-family: "Uber Move Bold";
  font-size: 24px;
  line-height: 30px;
  margin: 10px 0px;
  font-family: "Mulish", sans-serif;
  font-weight: 600;
}
.events-listing .viewall {
  margin-left: auto;
  font-size: 18px;
  line-height: 21px;
  text-align: right;
  text-decoration-line: underline;
}
.events-listing .item {
  margin-top: 30px;
  margin-bottom: 0;
}
.events-listing .col-img {
  flex: 0 0 40%;
  max-width: 40%;
  width: 100%;
  min-height: 1px;
  position: relative;
  padding: 15px;
}
.events-listing .col-content {
  flex: 0 0 60%;
  max-width: 60%;
  width: 100%;
  min-height: 1px;
  position: relative;
  padding: 15px 50px 15px 15px;
}
.events-listing p {
  font-size: 16px;
  line-height: 26px;
  opacity: 0.8;
}
.other-detail .flex-row {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  line-height: 23px;
  margin-top: 20px;
  opacity: 0.8;
}
.share-event {
  position: relative;
  border-top: 0.5px solid #3d3d3d;
  padding: 12px 40px 15px 15px;
  text-align: right;
}
.share-event a {
  background: url("../img/icon-share.svg") right center no-repeat;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  padding-right: 42px;
}
.breadcrumbs {
  font-size: 14px;
  line-height: 18px;
  color: #ffffff;
  margin: 25px 0px;
}
.breadcrumbs ul,
.breadcrumbs li {
  display: flex;
  list-style: none;
}
.breadcrumbs li a {
  opacity: 0.6;
}
.breadcrumbs li span {
  margin-left: 3px;
  margin-right: 5px;
  opacity: 0.6;
}
/* .page-eventDetails {
  background: url("../img/img-eventdetail.jpg") center center no-repeat;
  background-size: cover;
} */
.cancel-event {
  display: flex;
  text-align: right;
  justify-content: flex-end;
}
.event-details .content {
  max-width: 410px;
  margin-bottom: 30px;
}
.event-details .content .cat {
  line-height: 25px;
}
.event-details {
  margin-bottom: 50px;
}
.event-details h1 {
  font-weight: bold;
  font-size: 50px;
  line-height: 60px;
  letter-spacing: 0.015em;
  margin-bottom: 20px;
}
.event-details .desc {
  margin-bottom: 10px;
}
.event-details .date-time {
  line-height: 33px;
}
.event-details .date-time .flex-row div {
  margin-right: 20px;
}
.event-details .date-time .flex-row img {
  margin-right: 5px;
  vertical-align: middle;
}
.btn-cancelEvent {
  background-color: transparent;
  align-items: center;
  text-align: center;
  padding: 8px;
  border: 0;
  color: #fff;
  margin-left: auto;
  outline: 0;
  cursor: pointer;
  position: relative;
  top: -40px;
  opacity: 1;
  border: 1px solid #fff;
  background: rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.35);
  border-radius: 5px;
  margin-right: 5px;
}
.btn-cancelEvent img {
  vertical-align: middle;
}
.btn-cancelEvent span {
  vertical-align: middle;
  font-size: 18px;
  line-height: 19px;
  letter-spacing: 0.65625px;
  /* text-decoration: underline; */
  margin-right: 7px;
}
/* .btn-cancelEvent:hover {
  opacity: 1;
} */
.icon-viewpswd {
  position: absolute;
  left: inherit;
  right: 0;
  top: 5px;
  z-index: 2;
  cursor: pointer;
  height: 18px;
}

.icon-viewpswd img {
  vertical-align: top;
}

.icon-inputtype {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-79%);
}
 input:-webkit-autofill,
 input:-webkit-autofill:hover, 
 input:-webkit-autofill:focus, 
 input:-webkit-autofill:active{
    -webkit-background-clip: text;
    -webkit-text-fill-color: #ffffff;
    transition: background-color 5000s ease-in-out 0s;
    box-shadow: inset 0 0 20px 20px #0E0E0E;
}
.btn-wrap {
  margin-top: 40px;
}
.have-accuont {
  margin-top: 15px;
  text-align: center;
  font-size: 14px;
  line-height: 18px;
}
.field-wrap {
  position: relative;
  margin-bottom: 20px;
}
.field-wrap label {
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 5px;
  display: block;
}
.field-wrap input,
.field-wrap select {
  width: 100%;
  color: rgba(256, 256, 256, 0.8);
  font-size: 14px;
  line-height: 20px;
  font-family: "Mulish", sans-serif;
  padding-bottom: 3px;
  border: 0;
  border-bottom: 1px solid rgba(256, 256, 256, 0.5);
  padding: 3px 3px;
  margin-bottom: 5px;
  background: transparent;
  outline: 0 !important;
}

.field-wrap select {
  background-image: url("../img/arrow-down.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 2px) center;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.upload-details {
  padding: 40px 0px;
}
.page-login .flex-container {
  max-width: 1192px;
}

.grey-wrap {
  background: rgba(134, 134, 134, 0.1);
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.form-login .field-wrap input,
.form-forgotpaswd .field-wrap input {
  padding-left: 25px;
}
.grey-wrap .title {
  text-align: center;
  margin-bottom: 35px;
  color: rgba(255, 255, 255, 0.9);
}
.grey-wrap .title h1 {
  font-size: 35px;
  line-height: 42px;
  margin-bottom: 10px;
  text-align: center;
}

.grey-wrap .title p {
  font-size: 20px;
  font-family: "Mulish", sans-serif;
  font-weight: 400;
  margin: 0;
  text-align: center;
}

.grey-wrap .note {
  font-size: 12px;
  line-height: 16px;
  color: rgba(255, 255, 255, 0.8);
}

.grey-wrap .details {
  min-height: 135px;
}

.full-width {
  width: 100%;
}

.login-box {
  text-align: center;
  max-width: 330px;
  margin: 0px auto;
  font-size: 14px;
}

.login-box .grey-text {
  opacity: 0.8;
  margin-top: 10px;
  margin-bottom: 0;
}

.login-box form {
  font-size: 12px;
  color: rgba(256, 256, 256, 0.9);
}

.bank-wrap form {
  margin-top: 30px;
}

.forgotlink {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px 25px 0px;
  color: rgba(256, 256, 256, 0.9);
}

.login-box button {
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  font-family: "Mulish", sans-serif;
}

.or span:after,
.or span:before {
  content: "";
  width: 62px;
  height: 1px;
  opacity: 0.3;
  background: #fff;
  position: absolute;
  top: 50%;
  right: -100%;
}

.or span {
  position: relative;
  display: inline-block;
  margin: 30px 0px 15px 0px;
}

.or span:before {
  left: -100% !important;
  position: absolute;
}

.flex-justify-space {
  justify-content: space-between;
  display: flex;
}

.pure-checkbox {
  display: block;
  position: relative;
  padding-left: 19px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */

.pure-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */

.checkmark {
  position: absolute;
  top: 0px;
  left: 0;
  height: 13px;
  width: 13px;
  background-color: transparent;
  border-radius: 3px;
  border: 1px solid;
}

/* On mouse-over, add a grey background color */

.pure-checkbox:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */

.pure-checkbox input:checked ~ .checkmark {
  background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */

.pure-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */

.pure-checkbox .checkmark:after {
  left: 4px;
  top: 3px;
  width: 2px;
  height: 4px;
  border: solid white;
  border-width: 0 1px 1px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.pure-checkbox {
  position: relative;
  line-height: normal;
  margin-bottom: 0 !important;
}

.login-box .field-wrap input {
  padding-bottom: 5px;
}

.forgetPswd-wrap .field-wrap {
  margin: 50px 0px;
}

input:focus {
  outline: none;
}

.img.side-line {
  position: relative;
  text-align: center;
}

.img.side-line:after {
  position: absolute;
  top: 50%;
  width: 0.5px;
  height: 100%;
  background: #fff;
  transform: translateY(-50%);
  content: "";
  opacity: 0.15;
  right: 0px;
}

.login-box.reset input {
  padding-left: 0px;
  padding-right: 15px;
  margin-top: 15px;
}

.login-box.reset input + img {
  left: auto;
  right: 0px;
}

.login-box.reset {
  width: 322px;
}

.login-wrap .img,
.forgetPswd-wrap .img {
  padding: 30px 30px 30px 0px;
}

.login-wrap,
.reset-wrap {
  padding-top: 60px;
  padding-bottom: 50px;
}

.verifyOtp-wrap {
  max-width: 527px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px;
  padding-bottom: 60px;
}

.verifyOtp-wrap .login-box {
  max-width: 380px;
}

.bank-wrap {
  max-width: 527px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 60px;
  padding-bottom: 60px;
}

.bank-wrap input {
  padding-left: 5px;
}

.otp input {
  background: rgb(255 255 255 / 25%);
  box-shadow: none;
  border-radius: 10px;
  height: 70px;
  width: 60px;
  text-align: center;
  margin-bottom: 30px;
  color: #fff;
  font-size: 30px;
  font-family: "Uber Move Bold";
  font-weight: bold;
  border: 1px solid;
  margin: 0px 5px 40px;
}

.user-section {
  margin-bottom: 60px;
}

.register-section {
  margin-bottom: 50px;
}

.register-wrap {
  width: 818px;
  max-width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
  font-size: 12px;
  margin: auto;
}

.register-wrap .center-box {
  max-width: 562px;
  margin-left: auto;
  margin-right: auto;
}

.register-wrap form {
  color: rgba(256, 256, 256, 0.8);
}

.register-wrap .content {
  width: 500px;
}

.register-wrap .login-box button {
  width: 294px;
}

.register-wrap .note {
  padding: 5px 0 10px 0px;
}

.register-wrap .note p {
  font-size: 12px;
  line-height: 15px;
}

.flex-centered {
  display: flex;
  align-items: center;
}

.register-wrap .field-wrap.flex-col-2 input,
.register-wrap .field-wrap.flex-col-2 input {
  padding-left: 5px;
}

.register-wrap .flex-row:nth-child(3) .field-wrap.flex-col-2 input,
.register-wrap .flex-row:nth-child(4) .field-wrap.flex-col-2 input {
  padding-right: 20px;
}

.register-wrap .flex-row:nth-child(3) .field-wrap img,
.register-wrap .flex-row:nth-child(4) .field-wrap img {
  left: auto;
  right: 10%;
}

.register-wrap .flex-row:nth-child(4) .field-wrap img {
  top: 22px;
}

.profile-photo .file-field {
  width: 112px;
  height: 112px;
  margin: auto;
  border-radius: 50%;
  padding: 3px;
  position: relative;
  background-position: center;
  background-size: cover;
}

.dp-img-actor {
  background-image: url("../img/img_avatar.png");
  border: 0.4px solid white;
}

/*.profile-photo .file-field img {
  border-radius: 50%;
  border: 1px solid #fff;
  width: 100%;
  max-height: 112px;
  object-fit: cover;
}*/

.profile-photo input {
  width: 31px;
  height: 31px;
  opacity: 0;
  cursor: pointer;
}

.profile-photo .icon-upload {
  position: absolute;
  right: 0;
  bottom: 15px;
  background: url("../img/icon-photo-upload.svg") no-repeat center center;
  cursor: pointer;
}

.form-signup {
  margin-top: 40px;
}

.chng-arrow {
  -webkit-appearance: none;
  background-image: url("../img/arrow-down.svg") no-repeat 100% 50% !important;
  z-index: +999;
}
label.custom_file {
  position: absolute;
  left: 0;
  color: red;
  background: #0e0e0e;
  width: 99px;
  height: 33px;
  line-height: 41px;
  cursor: pointer;
}

.field-wrap input[type="file"] {
  border: 0px;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

label.custom_file img {
  position: relative;
  top: 10px;
}

.event-box input,
.event-box select {
  background: #171616;
  box-shadow: 0px 4px 32px rgb(0 0 0 / 20%);
  border-radius: 5px;
  border: 0px;
  padding: 10px;
}

label.empty {
  height: 22px;
}

.create-event {
  padding-bottom: 100px;
}
.create-event .form {
  max-width: 995px;
  margin: auto;
}
.form-wrap input,
.create-event .form input,
.create-event .form select,
.create-event .form input,
.create-event .form textarea {
  background-color: #171616;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  border: 0;
  padding: 15px 15px;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  color: rgba(256, 256, 256, 0.9);
  text-align: left;
  font-family: "Mulish", sans-serif;
}
.custom-select-dropdown select,
.create-event .form select {
  background-image: url("../img/whitearrow-down.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) center;
}
.custom-select-dropdown select {
  width: 100%;
  background-color: #3a3838;
  border: 0;
  border-radius: 5px;
  padding: 15px 15px;
  outline: 0 !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.event-box select.chng-arrow {
  width: 100%;
  background: #171616 url(../img/arrow-ios-down.svg) no-repeat 98% 50% !important;
}

.event-box .forgotlink.ticket {
  background: #0b0b0b;
  padding: 10px;
  border-radius: 5px;
}
.event-box textarea {
  width: 100%;
  background: #0c0b0b;
  color: #fff;
  border: 0px;
  border-radius: 5px;
  padding: 10px;
}
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}
input[type="date"] {
  background: #171616 url(../img/date.png) no-repeat 97% 50% !important;
}
input[type="time"] {
  background: #171616 url(../img/timer.png) no-repeat 97% 50% !important;
  margin-left: auto !important;
  display: block;
}
.field-wrap.flex-col-2.file-section label {
  width: 97px !important;
  top: 26px;
  background: black;
}
p img {
  vertical-align: middle;
}

.field-wrap.flex-col-2.file-section input {
  background: transparent;
  padding-top: 0px;
}
.top-heading h1 {
  border-bottom: 0.5px solid rgb(255 255 255 / 40%);
  text-align: center;
  padding-bottom: 15px;
  margin-bottom: 50px;
  font-size: 30px;
  
}
.file-section {
  margin-bottom: 20px;
}
.field-wrap.email_text {
  margin-top: 30px;
}

.field-wrap.email_text input.text1 {
  width: 50% !important;
}

.field-wrap.email_text label {
  width: 100% !important;
  clear: both;
  float: left;
}

.field-wrap.email_text input.text2 {
  width: 30% !important;
}
.field-wrap.email_text img {
  position: relative;
  left: auto;
  top: auto;
  transform: unset;
}
.email_text div {
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  width: 100%;
}
.small {
  font-size: 11px;
}
.reset-box .field-wrap input {
  padding-left: 0;
}
.bank-wrap .note {
  font-size: 12px;
  line-height: 15px;
  color: rgba(256, 256, 256, 0.8);
  margin: 20px 0 0 0px;
}
.checkbox .ticket {
  background-color: #171616;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  border: 0;
  padding: 15px 15px;
}
.checkbox .ticket .checkmark {
  top: 5px;
}
.checkbox input {
  padding: 0 !important;
  margin: 0 !important;
}
.field-upload {
  display: flex;
  position: relative;
}
.field-upload input {
  background: transparent;
  height: 25px;
  position: absolute;
  left: 0;
  opacity: 0;
  cursor: pointer;
  width: 130px;
}
.field-upload p {
  font-size: 16px;
  margin-bottom: 0;
}
.label-upload {
  margin-right: 10px;
  color: #e50914;
  font-size: 14px;
  line-height: 20px;
  padding-right: 20px;
  cursor: pointer;
  background: url("../img/icon-attach.svg") right 5px no-repeat;
}
.corp-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.corp-price input {
  width: 79% !important;
}
.myaccount-section {
  margin-bottom: 100px;
}
.myaccount .top-heading h1 {
  text-align: left;
}
.myaccount-item {
  color: rgba(255, 255, 255, 0.7);
  padding-top: 30px;
  padding-bottom: 20px;
  border-bottom: 1px solid rgb(255, 255, 255, 0.5);
}
.myaccount-item:first-child {
  padding-top: 0;
}
.myaccount-item .title {
  display: flex;
  align-items: center;
  font-weight: bold;
}
.myaccount-item .title h4 {
  font-family: "Mulish", sans-serif;
  font-weight: bold;
  font-size: 22px;
  line-height: 28px;
  text-decoration: underline;
  margin: 0;
}
.myaccount-item p {
  color: rgba(255, 255, 255, 1);
  margin-bottom: 15px;
}
.myaccount-item .title img {
  margin-right: 10px;
}
.myaccount-item .btn-edit {
  background: transparent;
  border: 0;
  outline: 0;
  cursor: pointer;
}
.social-profile {
  color: rgba(255, 255, 255, 1);
  cursor: pointer;
  display: inline-block;
}
.social-profile img {
  vertical-align: middle;
  margin-right: 7px;
}
.active-toggle {
  background: transparent;
  border: 0;
  outline: 0;
  height: 30px;
}
.myaccount-item .btn-text {
  color: #e50914;
  text-decoration: underline;
}
.myaccount-item .link-text {
  color: rgba(255, 255, 255, 1);
  text-decoration: underline;
}
.myaccount-item .dp {
  width: 95px;
  height: 95px;
}
.myaccount-item .dp img {
  width: 100%;
  max-height: 95px;
  object-fit: cover;
  border: 2px solid #fff;
  border-radius: 50%;
}
.form-bankingInfo {
  max-width: 995px;
  margin: auto;
}
.banking-information .top-heading h1 {
  text-align: center;
}
.form-bankingInfo .field-wrap input {
  margin-bottom: 15px;
}
.emailverify-section .login-box {
  width: 382px;
}
.bank-wrap .center-box {
  max-width: 300px;
  margin: auto;
}
.login-box .bottom-links {
  padding: 30px 0 0 0px;
}
.login-box .bottom-links p {
  color: rgba(255, 255, 255, 0.8);
  margin-bottom: 8px;
}
.reset-confirm-wrap {
  max-width: 527px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 60px;
}
.page-section {
  margin-bottom: 100px;
}
.page-section .title {
  text-align: center;
  margin-bottom: 35px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
}
.page-section .title h1 {
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 15px;
}
.page-section .content {
  padding: 0 25px;
}
.notification-items .title {
  display: flex;
  justify-content: space-between;
  margin: 0px 0px 5px 0px;
}
.notification-items .title * {
  margin: 0;
}
.notification-items .img {
  margin-right: 10px;
}
.notification-items img {
  border-radius: 6px;
  width: 130px;
}
.notification-items .item {
  padding: 18px 20px 18px 20px;
  /* border-top: 1px solid rgba(255, 255, 255, 0.2); */
  overflow: hidden;
  background: rgb(134 134 134 / 70%);
  border-radius: 10px;
  margin: 15px 0px 15px 0px;
}
.notification-items .item:first-child {
  border: 0;
}
.notification-items h4 {
  font-family: "Mulish", sans-serif;
  color: #e50914;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
}
.notification-items p {
  font-size: 15px;
  line-height: 20px;
  margin: 0;
}
.notification-items .date {
  font-size: 12px;
  line-height: 20px;
}
.btn-rate-event {
  padding: 6px 13px !important;
  font-size: 14px;
  line-height: 20px;
  float: right;
  position: relative;
  z-index: 2;
  margin-top: -8px;
}
.rate-sidebar-model {
  display: none;
}
.flex-item {
  display: flex;
}
.reason-deactivate {
  border: 0.5px solid #ececec;
  background-color: transparent;
  padding: 15px;
  outline: 0;
  font-size: 18px;
  line-height: 23px;
  color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  width: 58%;
  height: 173px;
  font-family: "Mulish", sans-serif;
  resize: none;
}
.pswd-deactivate {
  background: #161313;
  opacity: 0.5;
  outline: 0;
  border: 0;
  width: 100%;
  padding: 15px 15px;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  font-size: 18px;
  line-height: 23px;
  color: rgba(255, 255, 255, 1);
}
.pswd-deactivate input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.model-content .btn-row {
  margin-top: 30px;
}
.custom-date-time input {
  background-color: #3a3838 !important;
  border: 0;
  padding: 13px 20px;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.reschedule-model .field-wrap label {
  margin-bottom: 10px;
}
.reschedule-model .model-head h2 {
  font-size: 30px;
  line-height: 38px;
  color: #fff;
  text-align: center;
}
.enduser-events .item {
  padding: 16px;
  border-radius: 5px;
}
.enduser-events .name,
.enduser-events .name h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Mulish", sans-serif;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  color: rgba(255, 255, 255, 1);
  margin: 0;
}
.enduser-events .ticket,
.enduser-events .date-time {
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  line-height: 20px;
  color: rgba(255, 255, 255, 0.9);
}
.enduser-events .name,
.enduser-events .ticket,
.enduser-events .date-time {
  margin-top: 10px;
}
.enduser-newsletter {
  background: #000 url("../img/newletter-img2.svg") 0 0 no-repeat;
  padding: 100px 0 140px 0px;
}
.enduser-section {
  margin-bottom: 50px;
}
.page-enduserhome {
  /* background: url("../img/img-eventdetail.jpg") 0 0 no-repeat; */
  /* background-size: contain; */
}
.enduser-section {
  padding-top: 50px;
}
.enduser-section .event-details .date-time {
  margin-bottom: 10px;
}
.enduser-artist {
  margin-top: 90px;
}
.enduser-artist .row-content {
  display: flex;
}
.enduser-artist .row-content .img {
  margin-right: 10px;
}
.enduser-artist .row-content .img img {
  border-radius: 6px;
  width: 88px;
}
.enduser-artist .content {
  margin-bottom: 0;
}
.enduser-artist h4 {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 14px;
}
.enduser-artist h5 {
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 0px;
}
.enduser-artist h6 {
  font-size: 9px;
  line-height: 12px;
  margin: 0;
}
.enduser-artist .rating {
  display: flex;
  align-items: center;
  margin-top: 5px;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  margin-bottom: 5px;
}
.rating .stars {
  margin-left: 5px;
}
.rating .stars img {
  vertical-align: middle;
}
.enduser-artist .p-box {
  max-width: 251px;
  padding: 9px 11px;
  background: #0c0c0c;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  font-size: 12px;
  line-height: 15px;
  margin: 0;
}
.sorting-auction {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.sortby-dropdown {
  position: relative;
}
.sortby-dropdown a {
  font-size: 18px;
  line-height: 21px;
  background: url("../img/icon-menu.svg") right center no-repeat;
  padding-right: 23px;
}
.sortby-dropdown .dropdown {
  position: relative;
  background: url("../img/icon-menu.svg") right center no-repeat;
  margin-left: 30px;
}
.sortby-dropdown .dropdown .icon {
  text-indent: 0;
  width: 42px;
}
.sortby-dropdown .dropdown .icon img {
  height: 18px;
  width: 18px;
  border-radius: 2px;
  vertical-align: top;
}
.sortby-dropdown ul {
  display: none;
  padding-top: 10px;
  overflow: hidden;
  list-style: none;
  position: absolute;
  z-index: 9;
  right: 0;
  -webkit-border-bottom-right-radius: 5px;
  -webkit-border-bottom-left-radius: 5px;
  -moz-border-radius-bottomright: 5px;
  -moz-border-radius-bottomleft: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}
.sortby-dropdown:hover ul {
  display: block;
}
.sortby-dropdown ul li a {
  white-space: nowrap;
  background: #211e1e;
  font-size: 16px;
  line-height: 20px;
  padding: 15px 20px;
  display: block;
  border-top: 1px solid #292626;
}
.sortby-dropdown ul li:first-child a {
  border: 0;
}
.sortby-dropdown select {
  cursor: pointer;
  appearance: none;
  background-color: black;
  outline: 0px;
  border: none;
  color: #fff;
  font-size: 16px;
  width: 160px;
  background: url("../img/icon-menu.svg") right center no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.filterby {
  position: relative;
  margin-left: 20px;
}
.filterby-options {
  display: none;
  position: absolute;
  right: 0;
  top: 30px;
  z-index: 9;
  width: 300px;
  min-height: 300px;
  padding: 30px 35px;
  background: #211e1e;
  box-shadow: -4px 3px 4px rgba(0, 0, 0, 0.04);
  border-radius: 0px 0px 5px 5px;
}
/* .filterby:hover .filterby-options {
  display: block;
} */

.filterby-options.slow {
  display: block;
}

.events-listing .download {
  color: #e50914 !important;
  font-weight: 600;
  background: url("../img/icon-downloadred.svg") 0 center no-repeat;
  padding-left: 30px;
}
.events-listing .download {
  margin-top: 5px;
}
.rate-options {
  padding: 25px 25px;
  border-radius: 10px;
  background: #1b1b1b;
}
.rate-options .primary-button {
  width: 100%;
}
.radio-btn-wrap {
  background: #3a3838;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 12px 10px;
}
.radio-btn-wrap input {
  width: 15px;
  height: 15px;
  padding: 0;
  margin: 0 10px 0 0px;
  opacity: 0;
  z-index: 9;
  cursor: pointer;
}
.radio-btn-wrap span {
  position: absolute;
  width: 20px;
}
.radio-btn-wrap label {
  margin: 0;
  position: relative;
}

.layer {
  /* background: radial-gradient(circle at 166%, #fcfffc00, #1413132e 50%, #eeeeee00 75%, #090909 85%, to bottom ); */
  /* background-color: radial-gradient(circle at 151%, #fcfffc00, #14131300 50%, #eeeeee00 75%, #090909 85%); */
  /* background: linear-gradient(to right, #000 0%, #00000000); */
  background: radial-gradient(closest-corner at 69% 35% ,#fdfdff00,#0e0e0e00,#05050500,#000000e3);
  /* background-image: linear-gradient(180deg, red, yellow); */
  width: 100%;
  /* height: 100vh; */
  height: 750px;
  position: absolute;
  z-index: 1;
}

.bg_video {
  height: 750px;
  overflow: hidden;
  position: absolute;
  width: 100%;
}

.aboutusback {
  background: #000 !important;
  background-color: #000 !important;
}
.btn-con{
 align-self: center;
  text-align: center;
  /* margin-left: 65px; */
  padding-bottom: 50px;

}
/******************************************custom code*starts********************************************************/
.download-btn-container {
  margin-top: 20px;
  display: flex;
  align-items: center;
}

.download-btn-container a {
  font-family: Mulish;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: left;
  color: #e50914;
}

.download-btn-container a img {
  padding-right: 20px;
}

.border-change textarea {
  border: 0.5px solid rgba(236, 236, 236, 0.5);
}

.change-space {
  margin-top: 40px;
}

.change-space textarea {
  resize: none;
}

.contact-icons-wrapper {
  width: 100%;
  margin-top: 40px;
}

.contact-icons-wrapper .contact-icon-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.contact-icon-col span {
  padding-left: 10px;
}

.contact-icon-col img {
  vertical-align: middle;
}

.make-booking-wrapper {
  width: 100%;
}

.col-inner-row {
  display: flex;
}

.outer-col-width {
  width: 60%;
}

.outer-col-2 {
  width: 40%;
}

.col-img-width {
  width: 40%;
}

.col-img-width img {
  width: 100%;
  min-height: 262px;
}

.space-add {
  padding-left: 20px;
}

.space-add h3 {
  font-family: Mulish;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
}

.space-add h4 {
  font-family: Mulish;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
}

.text-con p {
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}

.con-label {
  margin-bottom: 5px;
}

.inner-labels {
  margin: 10px 20px;
}

.inner-labels label {
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.filed-con-inner {
  background: rgba(134, 134, 134, 0.2);
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}

.filed-con-inner .inner-labels input {
  background: #0b0a0a;
  box-shadow: none;
}

.symbol-x {
  position: relative;
}

.symbol-x::before {
  content: "X";
  position: absolute;
  top: 38px;
  left: -18px;
  color: white;
}

.text-con textarea {
  resize: none;
}

.flex-row-3 {
  width: 100%;
  display: flex;
}

.width-email {
  width: 50%;
}

.width-price {
  width: 25%;
}

.width-sell-price {
  width: 25%;
}

.pirice-add {
  position: relative;
}

.note-wrapper p {
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.icon-plus-wrapper {
  position: absolute;
  top: 40px;
  right: -40px;
}

.left-space-fix {
  padding-left: 0px !important;
}

.input-pay-wrapper {
  background-color: rgba(134, 134, 134, 0.3);
  vertical-align: middle;
  min-height: 50px;
  border-radius: 5px;
  position: relative;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.2);
}

.icons-container {
  position: absolute;
  top: 8px;
  right: 14px;
}

.input-pay-wrapper .space-lhs {
  line-height: 50px;
}

.checkmark-pay {
  top: 18px;
  left: 14px;
  height: 13px;
  width: 13px;
  border-radius: 18px;
}

.space-bw {
  margin-right: 5px;
}

.space-lhs {
  padding-left: 50px;
}

#fix-align {
  margin-left: 40px;
}

#fix-align img {
  vertical-align: middle !important;
  padding-right: 10px;
}

.plus-btn {
  background: url("../img/add.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  border: none;
  height: 20px;
  width: 20px;
  top: 15px;
  cursor: pointer;
}

.boder-con {
  border-bottom: 1px solid white;
  width: 100%;
}

.check-row {
  display: flex;
}
.space-2 {
  padding-left: 20px;
}

.col-space-1 {
  margin-left: 40px;
}

.corner-space {
  padding: 20px;
}

.corner-space label {
  font-size: 14px;
}

.corner-space input {
  box-shadow: none;
  position: relative;
}
.card-icon-con {
  position: absolute;
  right: 35px;
  top: 56px;
}

.drop-wrapper {
  width: 100%;
}

.drop-row-1 {
  width: 100%;
  display: flex;
  align-items: baseline;
}

.drop-col-1 {
  width: 30%;
  margin-right: 30px;
}

.drop-row-1 .drop-col-1 select {
  border: none;
  padding: 15px 15px;
  background-color: #171616;
  border-radius: 5px;
  background-position: calc(100% - 10px) center;
  z-index: +9999;
  background-image: url(../img/icom-drop-down-arrow-fille.png);
}

.w-change {
  width: 40%;
  margin-right: auto;
  margin-left: 100px;
  justify-content: flex-end;
}

.cvv-wrap {
  position: relative;
}

.label-cvv {
  position: absolute;
  top: -26px;
  left: 0px;
}

.card-icon-cvv {
  position: absolute;
  right: 24px;
  top: 12px;
}

.remove-border {
  border: none !important;
}

.fix-opaicty {
  opacity: 0.5 !important;
}

.card-details-row {
  display: flex;
  width: 100%;
}

.card-details-row .card-details-col-1 {
  width: 60%;
  margin-right: 20px;
}

.card-details-row .card-details-col-2 {
  width: 30%;
}

.cvv-outer {
  position: relative;
}

.modal-card-icon-cvv {
  position: absolute;
  top: 40px;
  right: -32px;
}

.view-all-btn a {
  font-size: 18px;
  text-decoration: underline;
}

.notify-row {
  width: 100%;
  display: flex;
}

.img-wrap {
  width: 20%;
}

.img-wrap img {
  width: 100%;
}

.content-notify {
  width: 80%;
  margin-left: 20px;
}

.notify-inner-row {
  display: flex;
  justify-content: space-between;
}

.notify-inner-row h3 {
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
  color: #e50914;
}

.notify-inner-col p {
  font-family: Mulish;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: right;
}

.content-notfiy {
  font-family: Mulish;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.notify-btn {
  text-align: right;
}

.notify-btn button {
  background-color: #e50914;
  font-family: Nunito;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 12px;
  letter-spacing: 0px;
  text-align: left;
  border: 1px solid #e50914;
  outline: 0 !important;
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  filter: drop-shadow(0px 4px 20px rgba(229, 9, 20, 0.3));
  color: #fff;
  padding: 10px 15px 10px 15px;
  border-radius: 100px;
  cursor: pointer;
}

.bottom-line {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
}

#font-size-change .content-notfiy .notify-text {
  font-size: 14px;
}

.filter-menu-wrapper {
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.checkmark-2 {
  top: 35px !important;
}

.chng-forgot {
  margin-top: -40px;
}

.minus-price {
  top: 12px;
}

.chng-align {
  justify-content: center;
  border-bottom: 1px solid rgba(225, 225, 225, 0.4);
  padding-bottom: 10px;
}

.icon-minus-field {
  position: absolute;
  right: 0px;
  top: -5px;
}

.minus-outer {
  position: relative;
}

.code-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid rgba(225, 225, 225, 0.6);
}

.inner-bookings-row {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.col-lhs-booking {
  width: 60%;
  background-color: rgba(134, 134, 134, 0.2);
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 46px 20px;
}

.col-rhs-booking {
  width: 40%;
  background-color: rgba(134, 134, 134, 0.2);
  margin-left: 20px;
  border-radius: 5px;
}

.row-book {
  display: flex;
  width: 100%;
}

.title-make-bookings h3 {
  font-family: Mulish;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 10px;
}

.title-make-bookings h4 {
  font-family: Mulish;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: left;
  margin-bottom: 15px;
}

.cols-lhs {
  width: 30%;
}

.cols-lhs img {
  width: 100%;
  border-radius: 5px;
  vertical-align: bottom;
}

.cols-rhs {
  width: 70%;
}

.content-make-bookings {
  margin-left: 15px;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 5px;
  min-height: 172px;
  font-family: Mulish;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0px;
  text-align: left;
}

.make-booking-wrapper {
  margin: 50px 0px;
}

.title-make-bookings {
  padding: 0px 15px;
}

.wrapper-artist {
  margin-top: 50px;
}

.artist-lhs {
  width: 25%;
}

.artist-rhs {
  width: 75%;
}

.artist-lhs img {
  width: 100% !important;
  border-radius: 5px;
}

.artist-content {
  min-height: 0px;
}

.row-artist {
  display: flex;
}

.artist-title {
  font-family: Mulish;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: left;
}

.artist-main {
  padding-left: 0px;
}

.like-icon-artist {
  color: red;
  padding-left: 10px;
}

.like-con-book {
  margin-bottom: 28px;
  display: inline-block;
  color: red;
  cursor: pointer;
}

.like-con-book img {
  margin-right: 5px;
  margin-bottom: 5px;
}

.book-form-title {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 10px 10px;
  border-radius: 5px;
}

.book-form-title h4 {
  margin-bottom: 0px;
}

.field-container {
  padding: 40px 10px;
}

.field-container label {
  font-weight: 400;
}

.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  z-index: 0;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 60px;
  margin-left: 0;
  background: #0e0e0e;
  border: 1px solid #0e0e0e;
  border-radius: 5px;
  line-height: 25px;
  height: 27px;
  width: 300px;
}
.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: #0e0e0e;
  border: 1px solid #0e0e0e;
  border-radius: 3px 0 0 3px;
}
.react-tel-input .country-list {
  outline: none;
  z-index: 1;
  list-style: none;
  position: absolute;
  padding: 0;
  margin: 10px 0 10px -1px;
  box-shadow: 1px 2px 10px rgb(0 0 0 / 35%);
  background-color: #0e0e0e;
  width: 300px;
  max-height: 224px;
  overflow-y: scroll;
  border-radius: 0 0 3px 3px;
}
.react-tel-input .flag-dropdown.open .selected-flag,
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background: #0e0e0e;
}
.react-tel-input .country-list li {
  background-color: #0e0e0e !important;
}
.hide {
  z-index: 0;
}
.phonenumber {
  position: relative;
}
.phonenumber::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  background-color: rgba(225, 225, 225, 0.6);
  bottom: 0;
  left: 0;
}

/* .phonenumber-myaccount .react-tel-input .flag-dropdown.open .selected-flag,
.phonenumber-myaccount .react-tel-input .selected-flag:hover,
.phonenumber-myaccount .react-tel-input .selected-flag:focus {
  background-color: rgba(0, 0, 0, 1);
} */

.phonenumber-myaccount .react-tel-input .form-control,
.phonenumber-myaccount .react-tel-input .flag-dropdown.open .selected-flag,
.phonenumber-myaccount .react-tel-input .selected-flag:hover,
.phonenumber-myaccount .react-tel-input .selected-flag:focus {
  border: 1px solid rgba(0, 0, 0, 1) !important;
  background-color: rgba(0, 0, 0, 1) !important;
  width: 90%;
}

.phonenumber-myaccount .react-tel-input .flag-dropdown,
.phonenumber-myaccount .react-tel-input .flag-dropdown.open .country-list li {
  border: 1px solid rgba(0, 0, 0, 1) !important;
  background-color: rgba(0, 0, 0, 1) !important;
}

.phonenumber-myaccount::after {
  width: 90%;
}

.input-conatiner .change-field input {
  width: 90%;
  background-color: rgba(134, 134, 134, 0.1);
  border-bottom: none;
  padding: 10px 10px;
  border-radius: 5px;
  margin-top: 10px;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}
.react-tel-input .flag-dropdown.open {
  background: #0e0e0e;
}

.input-conatiner .change-field .icon-plus-wrapper {
  right: 6px;
  top: 44px;
}
.user-row {
  display: flex;
  justify-content: space-between;
}

.user-col-rhs {
  text-align: right;
}

.full-user {
  margin-top: 10px;
}

.full-user .change-field input {
  width: 100%;
}

.change-tick {
  top: 16px;
  left: 10px;
  border-radius: 100%;
}

.pay-mode-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.pay-mode-row .col-pay-mode .pay-check {
  background-color: rgba(134, 134, 134, 0.1);
  border-bottom: none;
  padding: 10px 30px;
  border-radius: 5px;
  margin-top: 10px;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.1);
  margin-bottom: 10px !important;
}

.pay-mode-row .col-pay-mode:first-child {
  width: 100%;
  /* margin-right: 10px; */
}

/* .pay-mode-row .col-pay-mode:last-child {
  width: 20%;
  padding-right: 15px;
} */

.pay-mode-row .col-pay-mode:last-child input {
  width: 100%;
  padding: 12px 10px;
  margin-top: 8px;
}

.col-pay-mode .change-option {
  text-align: right;
}

.col-pay-mode .change-option a {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0px;
  text-decoration: underline;
}

.btn-pay-con {
  text-align: center;
}

.booking-event-enduser {
  margin: 200px 0px;
}

.book-enduer-details {
  margin-top: 220px;
  margin-bottom: 50px;
  justify-content: center;
  flex-direction: column;
}

.field-enduser-book input {
  border: none;
  background-color: rgba(134, 134, 134, 0.2);
  padding: 10px 10px;
  border-radius: 5px;
}

/* .end-user-bg-fix {
  background-size: cover;
} */

.book-enduer-details h2 {
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 60px;
  letter-spacing: 0.015em;
  text-align: center;
}

.book-enduer-details p {
  font-family: Mulish;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0px;
  text-align: center;
}

.field-enduser-book label {
  font-family: Mulish;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 10px;
}

.question-con {
  margin-top: 30px;
}

.question-con p {
  font-family: Mulish;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 25px;
}

.checkmark-3 {
  top: 6px;
  border-radius: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
}

.opt-list {
  margin-bottom: 15px;
  color: rgba(225, 225, 225, 0.9);
}

.description-wrap-end textarea {
  background-color: #171616;
  box-shadow: 0px 4px 32px rgb(0 0 0 / 20%);
  border-radius: 5px;
  border: 0;
  padding: 15px 15px;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
  color: rgba(256, 256, 256, 0.4);
  text-align: left;
  font-family: "Mulish", sans-serif;
}

.btn-wrap-end-book {
  text-align: center;
}

.book-msg-wrap {
  max-width: 716px;
}

.box-book-end {
  max-width: 450px;
}

.sidebar-model .rate-header {
  background: none;
  box-shadow: none;
}

.rate-change {
  flex-direction: column;
}

.col-rate-note {
  width: 100% !important;
}
.rate-space {
  padding-left: 20px;
}

.rate-check {
  top: 16px;
  left: 16px;
  border-radius: 100%;
}

.rate-items {
  border-top: none !important;
  padding: 0px !important;
}

.rate-wrap {
  margin-bottom: 0px !important;
}

.rate-body {
  margin-top: 100px;
}

.rate-label {
  margin-bottom: 20px;
}

.rate-check-space {
  margin-top: 30px !important;
}

.save-btn {
  margin-top: 20px;
}

.filter-title h5 {
  margin-bottom: 5px;
}

.checkbox .filter-item-change {
  background-color: transparent !important;
  box-shadow: none !important;
  padding: 0px;
}

.empty-change {
  margin-top: 25px;
  font-size: 16px;
  font-weight: 600px;
}

.filter-m-wrap label {
  font-size: 15px;
}

.check-filer-m {
  border: 1px solid red;
}

.filter-btn-row {
  display: flex;
  width: 100%;
}

.filter-btn-row .filter-btn-col:last-child {
  margin-left: 15px;
}

.filter-btn-col {
  width: 50%;
  margin-top: 30px;
}

.prime-btn-filter {
  padding: 0px;
  height: 32px;
  width: 100%;
  line-height: 32px;
  margin-top: 0px;
  font-size: 14px;
  box-shadow: none;
}

.filter-btn-row .filter-btn-col:last-child .prime-btn-filter {
  background-color: transparent;
  border: 1px solid #fff;
}

.filter-opt-2 {
  top: 26px;
}

.company-wrap {
  width: 65%;
}

.company-wrap input,
.company-wrap select,
.inner-field-wrapper input {
  width: 90%;
  color: #fff;
}

.company-wrap input::placeholder,
.inner-field-wrapper input::placeholder {
  width: 80%;
  color: rgba(255, 255, 255, 0.7);
}

.company-description input::placeholder {
  color: rgba(255, 255, 255, 0.4);
}

.add-company-web {
  right: 20%;
}

.flex-white p a {
  color: white !important;
}

.forget-mail input {
  padding-left: 25px;
}

.email-box {
  max-width: 360px !important;
}

.create-event .form textarea {
  resize: none;
}

.f-100-width {
  max-width: 57%;
}
.card-field-wrap {
  position: relative;
}
.bg-alert-change {
  background: rgba(23, 20, 20, 1);
}
.social-wrap {
  width: 200px;
  min-height: 55px;
  background: rgba(14, 14, 14, 1);
  border: 1px solid #fff;
  position: absolute;
  right: 0px;
  top: 48px;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  justify-content: flex-start;
  text-align: left;
  flex-wrap: wrap;
  display: none;
  z-index: +999;
}

.social-wrap-2 {
  display: flex;
}
.social-col {
  width: 25%;
  text-align: center;
}

.btn-space-cancel {
  margin-left: 20px;
}

.cursorPointer {
  cursor: pointer;
}

.fullWidth {
  width: 100%;
}

.displayBlock {
  display: block;
}

.corpTicket {
  width: 50%;
}

.banner-wrap {
  max-width: 100%;
  position: relative;
  display: flex;
}

.preview-img-wrap img {
  width: 100%;
}

.banner-content-box {
  max-width: 33%;
  overflow-wrap: anywhere;
}

.preview-img-wrap {
  max-width: 100%;
  position: relative;
}

.outer-wrappper-1 {
  width: 70%;
}

.preview-con {
  border: 2px solid red;
}

.event-details .content {
  /* z-index: +999; */
  position: relative;
}

.enduser-artist .row-content {
  /* z-index: +999; */
  position: relative;
}

.datepicker-react {
  padding: 8px;
}
.datepicker-react-input {
  width: 100%;
  background: black;
  color: white;
  outline: none;
  border: none;
}

.react-datepicker {
  border: 1px solid red;
  background: #000;
  color: #fff;
}
.react-datepicker__month-container {
  background: black;
  color: #fff;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: red;
}
.react-datepicker__header {
  background-color: #0e0e0e;
  border: 1px solid red;
  color: #fff;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-bottom-color: red;
}

.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover {
  background: red;
}

#date2 .react-datepicker__tab-loop .react-datepicker-popper {
  left: -100px !important;
}

#date2
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle {
  left: 148px !important;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__current-month,
.react-datepicker__day-name,
.react-datepicker__day, 
.react-datepicker-time__header {
  color: #fff;
}

.react-datepicker__day--disabled {
  color: #959595;
}

.react-datepicker__day--selected {
  background-color: red;
}

.react-datepicker__day:hover {
  background-color: rgb(52, 52, 189);
}

.react-datepicker__year-dropdown, .react-datepicker__month-dropdown {
  border: 1px solid red;
  background-color: #000;
}

.react-datepicker__month-option--selected_month, .react-datepicker__year-option--selected_year {
  background-color: red;
}

.react-datepicker__month-option:hover, .react-datepicker__year-option:hover {
  background-color: rgb(52, 52, 189);
}

.react-datepicker__day--keyboard-selected:hover {
  background-color: red;
}

.react-datepicker__month-read-view--down-arrow, .react-datepicker__year-read-view--down-arrow {
  top: 4px;
}

.react-datepicker__header__dropdown, .react-datepicker__header__dropdown--scroll {
  padding-top: 5px;
}

.react-datepicker__time-container .react-datepicker__time {
  background: #000;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  background-color: rgb(52, 52, 189);
}
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected,
.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: red;
}

.error {
  margin: 0 auto;
  text-align: center;
  height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.error .error-code,
.error .error-content {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}
.error-code {
  margin-bottom: 10px;
}
.error .error-code {
  color: #2d353c;
  font-size: 200px;
  line-height: 1;
  text-shadow: 10px 10px 0 rgba(45, 53, 60, 0.2);
  padding: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: flex-end;
  align-items: flex-end;
}

.panels {
  margin-top: 30px;
}
.mLeft {
  margin-left: 10px;
}

/** cookies wrapper***/
.cookies-wrapper {
  width: 100%;
  padding: 30px 20px;
  background-color: rgba(0, 0, 0, 0.95);
  box-shadow: 2px 2px 20px 0px rgba(255,255,255,0.2);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999999;
}


.cookies-container {
  max-width: 85%;
  margin: auto;

}


.cookies-main {
  color: #fff;

}

.cookies-wrapper .content-main .title {
  font-size: 18px;
  line-height: 1.2;
  font-family: "Mulish", sans-serif !important;
  font-weight: 500;
  margin-bottom: 12px;
}


.cookies-wrapper .content-main .content {
  font-size: 14px;
  font-family: "Mulish", sans-serif !important;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 10px;

}

.cookies-btn-main {
  text-align: end;
  padding: 15px 0px;
}

.cookies-btn {
  font-size: 12;
  font-family: "Mulish", sans-serif !important;
  padding: 10px;
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  cursor: pointer;
  transition: all .3s;
}


.cookies-btn:hover {
  color: #000;
  background-color: #fff;
  border: 1px solid #fff;
  font-family: "Mulish", sans-serif !important;
}

.all-btn {
  margin-left: 10px;
}


.custom-cookies-options {
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 4px;
}


.cookies-wrapper .check-label {
  font-size: 12px;
  line-height: 20px;
  margin-right: 10px;
}



.cookies-wrapper .check-label span {
  display: inline-block;
  padding-bottom: 4px;
}

.cookies-wrapper .cookies-check-box {
  margin: 0px;
}

.details-btn {
  font-size: 12px;
}
.custom-cookies-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.details-btn-main {
  cursor: pointer;
  background-color: #fff;
  color: #000;
  padding: 5px;

}



/**tab**/
.cookies-tab-main {
  display: none;
}


.tab-container {
  width: 100%;
  margin-top: 20px;
}



ul.tabs-cookie {
  margin: 0px;
  padding: 0px;
  list-style: none;
}
ul.tabs-cookie li{
  background: none;
  color: #fff;
  border-top:1px solid #fff ;
  border-right:1px solid #fff ;
  border-left:1px solid #fff ;
  border-bottom:none;
  display: inline-block;
  padding: 5px 10px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

ul.tabs-cookie li.current{
  background: rgba(0, 0, 0, 0.04);
  color: red;
}

.tab-content{
  display: none;
  background: rgba(133, 133, 133, 0.04);
  padding: 10px;
  border: 1px solid #fff;
  font-size: 12px;
  line-height: 1.5;
}

.tab-content.current{
  display: inherit;
}

.tab-content-title {
  font-size: 14px;
  margin-bottom: 8px;
}

.justifyEnd {
  justify-content: end !important;
}

.landing-hero-section h2 {
  font-size: 18px !important;
  line-height: 140% !important;
}

.landing-hero-section p {
  font-size: 18px !important;
}

.section {
  padding: 60px 0px;
}

/***tab***/

@media (max-width:1024px) {
  .cookies-container {
    max-width: 95%;
  }
}

@media (max-width:767px) {
  .cookies-container {
    max-width: 100%;
  }


  .tab-link {
    border: 1px solid #ffff !important;
    margin-bottom: 10px;
  }

  .details-btn-main {
    white-space: nowrap;
  }

  .cookies-wrapper .check-label {
    display: block;
  }
}
/**cookies wrapper****/

@media (max-width: 767.98px) {
  .error .error-code {
    font-size: 160px;
  }
}
@media (max-width: 575.98px) {
  .error .error-code {
    font-size: 140px;
  }
}
.error .error-content {
  top: 50%;
  bottom: 0;
  padding: 30px;
  background: #0e0e0e;
  /* background-image: url(images/error-page-pattern.png); */
}
.error .error-message {
  color: #fff;
  font-size: 24px;
  letter-spacing: 0.5px;
  margin-bottom: 5px;
}
.error .error-desc {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.6);
  letter-spacing: 0.5px;
}
.error-desc {
  margin-bottom: 30px;
}

.OT_publisher,
.OT_subscriber {
  width: 100% !important;
  height: 100vh !important;
}
.precall-live-video .OTPublisherContainer {
  width: 100% !important;
  height: 31vh !important;
}
.custom-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-mute-div {
  position: absolute;
  right: 0px;
  z-index: 5;
  background: rgba(255, 255, 255, 0.4);
  padding: 10px;
  border-radius: 100%;
  margin: 10px 10px 0px 0px;
  /* top: 200px; */
}

.mIcon{
  top: 200px
}

.shareevent-wrap {
  position: relative;
}

.sharedialog-wrap {
  position: absolute;
  top: -45px;
  right: -70px;
}

.sharedialog-livestream-wrap {
  position: absolute;
  top: 40px;
  right: 80px;
}

.description-scroll {
  max-height: 143px;
  overflow: hidden;
  position: relative;
  /* z-index: 2; */
}
.description-scroll:hover {
  overflow-y: auto;
}

.description-scroll::-webkit-scrollbar {
  width: 2px;
}

.description-scroll::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

.description-scroll::-webkit-scrollbar-thumb {
  background-color: rgb(100, 100, 100);
  outline: 1px solid rgb(51, 51, 51);
}

.description-scroll {
  -webkit-mask-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(255, 255, 255, 1)),
    to(rgba(255, 255, 255, 0.5))
  );
}

.events-search {
  text-align: center;
  padding: 10px;
  margin-top: 15px;
}

.events-search input {
  width: 60%;
  padding: 5px;
  background: rgba(15, 15, 15, 1);
  color: #fff;
  border-radius: 5px;
  line-height: 2;
  border: 2px solid rgba(29, 29, 29, 1)
}

.btn-applyVoucher {
  background-color: transparent;
  align-items: center;
  text-align: center;
  padding: 8px;
  color: #fff;
  margin-left: auto;
  outline: 0;
  cursor: pointer;
  position: relative;
  top: -4px;
  opacity: 1;
  border: 1px solid #666;
  background: rgba(0, 0, 0, 0.25);
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.35);
  border-radius: 5px;
}

.transparent-back {
  background: rgba(130,130,130, 50%) !important;
  z-index: 0 !important;
}

.for-overlay {
  position: relative;
}

.miscellaneous {
  line-height: 20px;
  padding-bottom: 25px !important;
}

/******************************custom media query starts here******************************/

@media (max-width: 767px) {
  .contact-icons-wrapper .contact-icon-row {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contact-icons-wrapper .contact-icon-row .contact-icon-col {
    margin-bottom: 20px;
  }

  .flex-row-3 {
    flex-direction: column;
  }

  .col-flex-3 {
    width: 100%;
  }
  .icon-plus-wrapper {
    position: absolute;
    top: 14px;
    right: -30px;
  }

  .drop-row-1 {
    flex-direction: column;
  }

  .w-change {
    margin: 0px;
    margin-top: 10px;
  }

  .label-cvv {
    position: static;
  }

  .drop-col-1 {
    width: 100%;
  }

  .card-icon-cvv {
    position: absolute;
    right: 14px;
    top: 42px;
  }

  .row-book {
    flex-direction: column;
  }

  .cols-lhs {
    min-height: 250px;
  }

  .cols-lhs,
  .cols-rhs {
    width: 100%;
  }

  .title-make-bookings {
    padding: 25px 0px;
  }

  .content-make-bookings {
    margin-left: 0px;
    min-height: 0px;
  }

  .row-artist {
    flex-direction: column;
  }

  .like-con-book {
    margin-bottom: 0px;
  }

  .artist-lhs {
    min-height: 250px;
  }

  .artist-lhs,
  .artist-rhs {
    width: 100%;
  }

  .btn-res-fix {
    padding: 0px 0px 0px 0px !important;
  }

  .media766 {
    width: 100%;
    height: 300px;
  }

  .corpTicket {
    width: 100%;
    padding-bottom: 10px;
  }

  .banner-wrap {
    flex-direction: column-reverse;
  }

  .eventImage {
    min-height: 25vh !important;
  }

  .outer-wrappper-1 {
    width: 100% !important;
  }

  .preview-con > video {
    height: 25vh !important;
  }

  .event-details .content {
    max-width: 100%;
  }

  .btn-cancelEvent span {
    display: none;
  }
  .video-mute-div {
    width: 50px;
    height: 50px;
  }

  .mIcon {
    top: 70px;
  }

  .events-search input {
    width: 100%;
  }

  .notification-items .item {
    padding: 18px 12px 18px 12px !important;
  }

  .space-lhs {
    padding-left: 20px;
  }

  .enableDisplay {
    display: block !important;
  }

  .sharedialog-wrap {
    top: -8px;
    right: -35px;
  }

  .sharedialog-livestream-wrap {
    top: 0px;
    right: 0px;
  }
  
  .layer {
    height: 600px;
  }

  .event-back {
    height: 30vh !important;
  }
  .col-height-change {
    height: auto !important;
  }
  .page-home, .page-events {
    height: 30vh !important;
  }

  .company-wrap {
    width: 100%;
  }

  .btn-cancelEvent {
    top:-23px !important;
  }

  .seo-section .content {
    padding-left: 0px;
  }
  .seo-section h1 {
    font-size: 25px;
    line-height: 31px;
  }

}

@media (max-width: 1024px) {
  .inner-bookings-row {
    flex-direction: column;
  }

  .col-lhs-booking {
    width: 100%;
  }

  .col-rhs-booking {
    width: 100%;
    margin-left: 0px;
    margin-top: 40px;
  }

  .input-conatiner .change-field input {
    width: 100%;
  }

  .page-home, .page-events {
    max-height: 50vh;
  }
}

/********************custom media query ends here****************************************/ /****************************************custom code ends*************************************************************/
@media (min-width: 768px) {
  .home-item:nth-child(odd) .flex-col-2:first-child,
  .hero-section .flex-col-2:first-child {
    order: 2;
  }
}


/********************************************************** Search box *****************************************************************************/

.searchBox{
  position:relative;
  width:100%;
  margin-left:30px;
    

}
.searchBox .inputBox{
  height: 40px;
  width: 30px;
  border-radius: 6px;
  position: relative;
  background: transparent;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}
.searchBox.input .inputBox{
  width: 272px;
  border-radius: 30px;
  background: transparent;
  transition: all .5s cubic-bezier(0.000, 0.105, 0.035, 1.570);
  background-color: #171616;
  box-shadow: 0px 4px 32px rgb(0 0 0 / 20%);
}
.searchBox .inputBox input {
  width: 100%;
  height: 40px;
  top: 0px;
  left: 0px;
  padding:0px 34px 0 12px;
  background: transparent;
  position: absolute;
  transform:translate(0,60px);
  box-sizing: border-box;
  opacity: 0;
  line-height: 30px;
  border:none;
  outline:none;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  transition: all .3s cubic-bezier(0.000, 0.105, 0.035, 1.570);
  transition-delay: 0.3s; 
  color: #fff;
}
.searchBox.input .inputBox input {
    opacity: 1;
    transform: translate(0, 0px);
    padding-top: 10px;
    padding-bottom: 10px;
}
.searchBox .inputBox input::-webkit-input-placeholder{
  color: rgba(256, 256, 256, 0.6);
  font-size: 16px;
}
.searchBox .inputBox button{
  height: 40px;
  width: 30px;
  position: relative;
  cursor:pointer;
  float: right;
  cursor:pointer;
  background: transparent;
  border:none;
  outline: none;
  border-radius: 6px;
  z-index: 2;
  transition: all 0.3s ease-in-out;
  padding: 0px; 
}
.searchBox.input .inputBox button{
  width:30px;
  height:40px;
  margin: 0px;
  border-radius: 30px;
  border-style: none;
}
.searchBox .inputBox button span{
  position:relative;
  background: url("../img/icon-search.svg") 0 center no-repeat;
  width:22px;
  height:22px;
  display:inline-block;
  vertical-align: middle;
  /* transform: rotate(45deg); */
  transition: all .4s cubic-bezier(0.650, -0.600, 0.240, 1.650);
}

.searchBox .closeBtn{
  height:30px;
  width:25px;
  position: absolute;
  border-radius: 8px;
  z-index: 1;
  top:2px;
  left: -28px;
  transform: rotate(-180deg);
  cursor: pointer;
  transition-delay: 0.3s;
  transition: all .3s cubic-bezier(0.285, -0.450, 0.935, 0.110);
}
.searchBox .closeBtn:before, .searchBox .closeBtn:after{
  content: '\00d7';
  position: absolute;
  color: #fff;
  font-size: 18px;
}

.searchBox.input .closeBtn{
  right: -50px;
  transition: all .6s cubic-bezier(0.000, 0.105, 0.035, 1.570);
  transition-delay: 0.3s;
}

.cls-btn-hide {
   display: none;
}

.sugesst-box-main {
  position: absolute;
  left: 0px;
  margin-top: 6px;
  width: 272px;
  max-height: 350px;
  background-color: #171616;
  box-shadow: 0px 4px 32px rgb(0 0 0 / 20%);
  padding: 10px;
  /* border: 0.4px solid rgba(131, 131, 131, 0.8); */
  border-radius: 5px 5px 15px 15px;
  overflow-y: scroll;
}

.sugesst-box-main::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
.sugesst-box-main::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}
.sugesst-box-main::-webkit-scrollbar-thumb {
  background-color: rgb(100, 100, 100);
  outline: 1px solid rgb(51, 51, 51);
}

.sugesst-box-main .sugesst-box {
  list-style: none;
}

.sugesst-box-main .sugesst-box li {
  padding: 5px 0px;
}

.sugesst-box-main .sugesst-box li a {
  padding: 5px 0px;
  display: block;
  font-size: 16px;
  text-overflow: ellipsis;
}

.hide-suggestions {
  display: none;
}

.searchTime {
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: 0.65625px;
  color: #FFFFFF;
  opacity: 0.5;
}

/****resposive search box***/
@media (max-width: 1270px) {
  .searchBox.input .inputBox, .sugesst-box-main {
    width: 200px;
  }
  .primary-button-login {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    /* width: 100px; */
    /* font-size: small; */
    padding: 5px 10px !important;
  }
}

@media (max-width: 990px) {
  .searchBox.input .inputBox, .sugesst-box-main {
    width: 272px !important;
  }
}

@media (max-width: 480px) {
  .searchBox {
    margin-left: 10px;
  }


  .notification-bell {
    margin-left: 10px !important;
  }

  .searchBox.input .inputBox, .sugesst-box-main {
    width: 150px !important;
  }

  .searchBox .inputBox, .searchBox .inputBox input, .searchBox.input .inputBox button, .searchBox .inputBox button {
    height: 30px !important;
  }

  .searchBox .closeBtn {
    height: 25px !important;
  }

  .sugesst-box-main .sugesst-box li a {
    padding: 5px 0px;
    display: block;
    font-size: 14px;
  }
}

@media (max-width: 450px) {
  .mLeft {
    margin-left: 0px;
  }
}

/****upcoming events section****/
.fluid-container {
  width: 90%;
  margin: auto;
  position: relative;
}




.upcomoing-events-section {
  text-align: center;
  background: linear-gradient(180deg,  #000000 100% , #262626 -22.26%,);
  padding: 50px 65px;
} 

.upcomoing-events-section .title-main {
  max-width: 75%;
  margin: auto;
  padding-bottom: 70px;
}

.upcomoing-events-section .flex-row {
  display: flex !important;
  align-items: center;
}


.upcomoing-events-section .slide-column {
  background: rgba(134, 134, 134, 0.1);
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  display: flex; 
  
}



.upcomoing-events-section .slide-column .columns {
  width: 50%;
  text-align: left;
  overflow: hidden;
}

.upcomoing-events-section .slide-column .columns img {
  box-sizing: border-box;
  border-radius: 5px;
  max-width: 100%;
  height: 100%;
}


.upcomoing-events-section .content-col {
  margin-left: 40px;
  margin-top: 10px;
}


.upcomoing-events-section .slide-column .col-title-main {
  text-align: left;
}

.upcomoing-events-section .slide-column .col-title-main h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 23px;
  margin-bottom: 20px;

}

.upcomoing-events-section .swiper-slide {
  width: 33.33%;
  min-height: 248px;
  padding: 10px 30px;
}


.upcomoing-events-section .event-details {
  list-style:none;
  margin-bottom: 0px !important;
}

.upcomoing-events-section .event-details li {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 20px;
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.upcomoing-events-section .slider-row-main {
  flex-wrap: nowrap;
}

.controls-wrapper {
  display: flex;
  justify-content: space-between;
  top: -124px;
  position: relative;
}

.controls-wrapper .swiper-button-prev , .controls-wrapper .swiper-button-next {
 position: relative;
 background-color: rgba(255, 255, 255, 0.3);
 box-shadow: -4px 3px 27px rgba(0, 0, 0, 0.1);;
 width: 32px;
 height: 32px;
 border-radius: 100%;

}

.controls-wrapper .swiper-button-prev {
  margin-left: -100px;
}

.controls-wrapper .swiper-button-next {
  margin-right: -70px;
}

.controls-wrapper .swiper-button-prev::after {
  display: none;
}

.controls-wrapper .swiper-button-next::after {
  display: none;

}

.upcomoing-events-section .view-all-link {
  margin-top: 50px;
  opacity: 0.6;
  text-decoration: underline;
}

@media (max-width:1280px) {
  .upcomoing-events-section .content-col {
   margin-left: 20px;
  }

  .upcomoing-events-section .slide-column .col-title-main h5 {
    white-space: nowrap;
  }

  .upcomoing-events-section .event-details {
    white-space: nowrap;
  }
}

@media (max-width:768px) {

  .upcomoing-events-section .title-main {
    padding-bottom: 0px;
    max-width: 100%;
  }


  .upcomoing-events-section .slider-row-main {
    margin: auto;
  }

  .upcomoing-events-section .slide-column {
    width: 100%;
    margin: auto;
  
  }

  .upcomoing-events-section .swiper-slide {
    padding: 10px 0px;
    min-height: 157px;
  }
  

  .upcomoing-events-section .title-main p {
    display: none;
  }

  .upcomoing-events-section .slide-column .col-title-main h5 {
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 18px;
  }

  .upcomoing-events-section .event-details li {
    margin-bottom: 10px;
    font-size: 13px;
    line-height: 16px;
  }


  .upcomoing-events-section .slide-column img {
     width: 100%;
     height: auto;
     margin-bottom: 0px;
  }

  .controls-wrapper .swiper-button-prev {
    margin-left: -18px;
  }
  
  .controls-wrapper .swiper-button-next {
    margin-right: -18px;
    margin-left: 40px;
  }

  .controls-wrapper {
    top: 40px;
    justify-content: center;
  }

  .upcomoing-events-section .view-all-link  {
    margin-top: 60px;
  }
}


/****upcoming events secrion****/

.video-section iframe {
  width: 100%;
  height: 610px;
}

.vid-content-section {
text-align: center;
}


.vid-content-section h3 {
margin-bottom: 40px;
}


.vid-content-section span {
color: #e50914;
}


.vid-content-section .section-content {
max-width: 90%;
margin:auto ;
}

.vid-content-section {
padding-top: 400px;
margin-top: -450px;
}

@media (max-width:1280px) {
.video-section iframe {
  height: 480px;
}

.vid-content-section {
  padding-top: 340px;
  margin-top: -380px;
}
}

@media (max-width:768px) {
.vid-content-section .section-content {
  max-width: 100%;
}

.video-section {
  padding: 50px 0px 0px 0px !important;
}

.video-section .fluid-container {
  width: 100%;
}

.video-section iframe {
  height: 280px;
  margin-top: 20px;
}

.vid-content-section {
  padding-top: 40px;
  margin-top: -10px;
}
}



/****video section home page****/

.swiper-scroll-wrap {
  position: relative;
  top: 45px;
  display: none;
}

.swiper-scroll-wrap .ctrls-mobile .swiper-pagination-bullet {
  margin: 0px 0px !important;
  width: 40px;
  height: 1px;
  background-color:rgba(255, 255, 255, 0.25);
  border-radius: 0px;
}

.swiper-scroll-wrap .ctrls-mobile .swiper-pagination-bullet-active {
  height: 2px;
  background-color: #ffff;
}

@media (max-width: 768px) {
  .swiper-scroll-wrap {
    display: flex;
  }
  .controls-wrapper {
    display: none;
  }
}
